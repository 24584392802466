import {
  getLoginArguments,
  LoginFn,
  LoginMutation,
} from "apollo/mutations/login";
import { useEffect } from "react";

const AutoLoggerSetter = ({
  login,
  password,
  username,
}: {
  login: LoginFn;
  password: string | undefined;
  username: string | undefined;
}) => {
  useEffect(() => {
    if (username && password) {
      setTimeout(async () => {
        const loginArgs = await getLoginArguments({ username, password });
        login(loginArgs);
      }, 0);
    }
  }, [username, password]);

  return null;
};

export default function MagicLogin({
  password,
  username,
}: {
  password: string | undefined;
  username: string | undefined;
}) {
  return (
    <LoginMutation>
      {(login: LoginFn) => (
        <AutoLoggerSetter
          username={username}
          password={password}
          login={login}
        />
      )}
    </LoginMutation>
  );
}
